export type GroupPaymentTypeName = ('regular'|'videos'|'exam'|'certificate');

export class GroupPaymentType {
    id?: number;
    title?: string;
    type?: GroupPaymentTypeName;
    description?: string;
    default?: boolean;

    static fromJson(fields: any): GroupPaymentType {
        let item = Object.assign(new this(), fields);
        return item;
    }
};
