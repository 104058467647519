import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { I18nService } from '../services/i18n.service';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    constructor(
        private i18nService: I18nService,

    ) { }

    transform(value?: Date|string, mode: ('both'|'future'|'past') = 'both', invalidDateLabel: string = ''): Date|string {
        const locale: string = this.i18nService.getLocaleDetails().language;
        const time: moment.Moment = moment(value);

        if (!time || !time.isValid()) {
            return invalidDateLabel;
        }
        if ((mode === 'future' && time.isBefore(moment()))
            || (mode === 'past' && time.isAfter(moment()))
        ) {
            return '';
        }

        if (moment().diff(time, 'day') >= 0 && moment().diff(time, 'day') < 1) {
            return time.locale(locale).format('HH:mm').toString();
        }

        return time.locale(locale).fromNow();
    }
}
