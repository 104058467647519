import { environment } from 'src/environments/environment';

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxPayPalModule } from 'ngx-paypal';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxColorsModule } from 'ngx-colors';

import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { CarouselModule } from 'primeng/carousel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ImageModule } from 'primeng/image';

import { AlertComponent } from './components/alert/alert.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppSidemenuComponent } from './components/app-sidemenu/app-sidemenu.component';
import { UserNamePipe } from './pipes/user-name.pipe';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CardGroupComponent } from './components/card-group/card-group.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CardJobComponent } from './components/card-job/card-job.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CardTeacherComponent } from './components/card-teacher/card-teacher.component';
import { SidebarCvComponent } from './components/sidebar/cv/cv.component';
import { UploadComponent } from './components/upload/upload.component';
import { ItemTemplateDirective } from './directives/item-template.directive';
import { UploadAvatarComponent } from './components/upload-avatar/upload-avatar.component';
import { SidebarRatingComponent } from './components/sidebar/rating/rating.component';
import { RoleAnyPipe } from './pipes/role-any.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RestoreTabDirective } from './directives/restore-tab.directive';
import { TabComponent, TabsComponent, SubTabComponent } from './components/tabs';
import { ListingTableComponent } from './components/listing-table/listing-table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { InputTextEditorComponent } from './components/input-text-editor/input-text-editor.component';
import { FilterPipe } from './pipes/filter.pipe';
import { UploadBlogComponent } from './components/upload-blog/upload-blog.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { ModalPaymentsViewComponent } from './components/modal-payments-view/modal-payments-view.component';
import { ModalPaymentsEditComponent } from './components/modal-payments-edit/modal-payments-edit.component';
import { MessageBubbleComponent } from './components/message-bubble/message-bubble.component';
import { PricePipe } from './pipes/price.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { CertificateComponent } from './components/certificate/certificate.component';
import { ModuleComponent } from './module/module.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SidebarMessageComponent } from './components/sidebar/message/message.component';
import { ModalSortItemsComponent } from './components/modal-sort-items/modal-sort-items.component';
import { PageWrapComponent } from './components/page-wrap/page-wrap.component';
import { SectionDirective } from './directives/section.directive';
import { SidebarPaymentsComponent } from './components/sidebar/payments/payments.component';
import { SidebarUpcomingCoursesComponent } from './components/sidebar/upcoming-courses/upcoming-courses.component';
import { SidebarCoursesComponent } from './components/sidebar/courses/courses.component';
import { InputInlineTextComponent } from './components/input-inline-text/input-inline-text.component';
import { ModalVideoComponent } from './components/modal-video/modal-video.component';
import { InputPaymentChooserComponent } from './components/input-payment-chooser/input-payment-chooser.component';
import { RequiredFieldDirective } from './directives/required-field.directive';
import { FilterCourseComponent } from './components/filter-course/filter-course.component';
import { AppLanguageSwitcherComponent } from './components/app-language-switcher/app-language-switcher.component';
import { EventsComponent } from './components/sidebar/events/events.component';
import { ModalTermsComponent } from './components/modal-terms/modal-terms.component';
import { CardGroupSmallComponent } from './components/card-group-small/card-group-small.component';
import { CardPaymentComponent } from './components/card-payment/card-payment.component';
import { PageAuthComponent } from './components/page-auth/page-auth.component';
import { ModalCreateUserComponent } from './components/modal-create-user/modal-create-user.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MenuActionsComponent } from './components/menu-actions/menu-actions.component';
import { DateLocalePipe } from './pipes/date-locale.pipe';
import { ModalImportCSV } from './components/modal-import-csv/modal-import-csv.component';
import { ModalInstructions } from './components/modal-instructions/modal-instructions.component';
import { CardCourseComponent } from './components/card-course/card-course.component';
import { PageFranchiseCourseEditComponent } from './components/page-franchise-course-edit/page-franchise-course-edit.component';
import { PageFranchiseCourseViewComponent } from './components/page-franchise-course-view/page-franchise-course-view.component';
import { SplashComponent } from './components/splash/splash.component';
import { ModalPollListComponent } from './components/modal-poll-list/modal-poll-list.component';
import { PageExamFormComponent } from './components/page-exam-form/page-exam-form.component';
import { PageJobDetailsComponent } from './components/page-job-details/page-job-details.component';
import { StudentContactComponent } from './components/student-contact/student-contact.component';
import { FormPartnerCustomizationComponent } from './components/form-partner-customization/form-partner-customization.component';
import { OverviewPartnerComponent } from './components/overview-partner/overview-partner.component';
import { FormPartnerComponent } from './components/form-partner/form-partner.component';
import { OverviewStudentComponent } from './components/overview-student/overview-student.component';
import { OverviewTeacherComponent } from './components/overview-teacher/overview-teacher.component';
import { ModalItemsChooseComponent }  from './components/modal-items-chooser/modal-items-chooser.component';
import { ButtonInfoComponent } from './components/button-info/button-info.component';
import { PillComponent } from './components/pill/pill.component';
import { ModalDateChooserComponent } from './components/modal-date-chooser/modal-date-chooser.component';
import { ModalCourseModuleFormComponent } from './components/modal-course-module-form/modal-course-module-form.component';

@NgModule({
    providers: [
        DatePipe,
        DecimalPipe,
    ],
    declarations: [
        AlertComponent,
        AppHeaderComponent,
        UserNamePipe,
        AppSidemenuComponent,
        AvatarComponent,
        CardGroupComponent,
        CardCourseComponent,
        CalendarComponent,
        CardJobComponent,
        LoadingComponent,
        CardTeacherComponent,
        SidebarCvComponent,
        UploadComponent,
        ItemTemplateDirective,
        UploadAvatarComponent,
        SidebarRatingComponent,
        RoleAnyPipe,
        SafePipe,
        IsArrayPipe,
        UploadFileComponent,
        BreadcrumbComponent,
        RestoreTabDirective,
        TabComponent,
        TabsComponent,
        SubTabComponent,
        PaginationComponent,
        ListingTableComponent,
        ModalConfirmComponent,
        InputTextEditorComponent,
        FilterPipe,
        UploadBlogComponent,
        UploadImageComponent,
        MessageBubbleComponent,
        ModalPaymentsViewComponent,
        ModalPaymentsEditComponent,
        PricePipe,
        DateAgoPipe,
        CertificateComponent,
        ModuleComponent,
        CarouselComponent,
        SidebarMessageComponent,
        ModalSortItemsComponent,
        PageWrapComponent,
        SectionDirective,
        SidebarPaymentsComponent,
        SidebarUpcomingCoursesComponent,
        SidebarCoursesComponent,
        InputInlineTextComponent,
        ModalVideoComponent,
        InputPaymentChooserComponent,
        CardPaymentComponent,
        CardGroupSmallComponent,
        RequiredFieldDirective,
        FilterCourseComponent,
        AppLanguageSwitcherComponent,
        EventsComponent,
        ModalTermsComponent,
        PageAuthComponent,
        ModalCreateUserComponent,
        MenuActionsComponent,
        DateLocalePipe,
        ModalImportCSV,
        ModalInstructions,
        PageFranchiseCourseEditComponent,
        PageFranchiseCourseViewComponent,
        SplashComponent,
        ModalPollListComponent,
        PageExamFormComponent,
        PageJobDetailsComponent,
        StudentContactComponent,
        FormPartnerCustomizationComponent,
        OverviewPartnerComponent,
        FormPartnerComponent,
        OverviewStudentComponent,
        OverviewTeacherComponent,
        ModalItemsChooseComponent,
        ButtonInfoComponent,
        PillComponent,
        ModalDateChooserComponent,
        ModalCourseModuleFormComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        AutoCompleteModule,
        InputNumberModule,
        InputMaskModule,
        SliderModule,
        ReactiveFormsModule,
        FormsModule,
        FullCalendarModule,
        InfiniteScrollModule,
        MatExpansionModule,
        MatTabsModule,
        DragDropModule,
        CKEditorModule,
        NgxPayPalModule,
        CarouselModule,
        NgxColorsModule,
        provideFirebaseApp(() => initializeApp(environment.services.firebase)),
        provideDatabase(() => getDatabase()),
        TranslocoModule,
        DragScrollModule,
        OverlayPanelModule,
        ImageModule,
    ],
    exports: [
        RouterModule,
        DropdownModule,
        MultiSelectModule,
        BreadcrumbModule,
        SelectButtonModule,
        TabViewModule,
        DialogModule,
        MenuModule,
        ToastModule,
        ListboxModule,
        CheckboxModule,
        SkeletonModule,
        CalendarModule,
        AutoCompleteModule,
        InputNumberModule,
        InputMaskModule,
        SliderModule,
        ReactiveFormsModule,
        FormsModule,
        AlertComponent,
        AppHeaderComponent,
        UserNamePipe,
        AppSidemenuComponent,
        AvatarComponent,
        CardGroupComponent,
        CardCourseComponent,
        FullCalendarModule,
        CalendarComponent,
        CertificateComponent,
        CardJobComponent,
        InfiniteScrollModule,
        LoadingComponent,
        CardTeacherComponent,
        SidebarCvComponent,
        UploadComponent,
        UploadAvatarComponent,
        SidebarRatingComponent,
        RoleAnyPipe,
        SafePipe,
        IsArrayPipe,
        UploadFileComponent,
        BreadcrumbComponent,
        RestoreTabDirective,
        TabComponent,
        TabsComponent,
        SubTabComponent,
        PaginationComponent,
        ListingTableComponent,
        DragDropModule,
        ItemTemplateDirective,
        ModalConfirmComponent,
        CKEditorModule,
        InputTextEditorComponent,
        FilterPipe,
        UploadBlogComponent,
        UploadImageComponent,
        MessageBubbleComponent,
        ModalPaymentsViewComponent,
        ModalPaymentsEditComponent,
        CardPaymentComponent,
        CardGroupSmallComponent,
        CarouselComponent,
        PricePipe,
        DateAgoPipe,
        SidebarMessageComponent,
        CarouselModule,
        ModalSortItemsComponent,
        SectionDirective,
        PageWrapComponent,
        SidebarPaymentsComponent,
        SidebarUpcomingCoursesComponent,
        SidebarCoursesComponent,
        InputInlineTextComponent,
        ModalVideoComponent,
        RequiredFieldDirective,
        FilterCourseComponent,
        AppLanguageSwitcherComponent,
        TranslocoModule,
        EventsComponent,
        InputPaymentChooserComponent,
        ModalTermsComponent,
        PageAuthComponent,
        ModalCreateUserComponent,
        MenuActionsComponent,
        DateLocalePipe,
        ModalImportCSV,
        ModalInstructions,
        PageFranchiseCourseEditComponent,
        PageFranchiseCourseViewComponent,
        SplashComponent,
        ModalPollListComponent,
        PageExamFormComponent,
        PageJobDetailsComponent,
        StudentContactComponent,
        FormPartnerCustomizationComponent,
        NgxColorsModule,
        OverviewPartnerComponent,
        OverlayPanelModule,
        ImageModule,
        FormPartnerComponent,
        OverviewStudentComponent,
        OverviewTeacherComponent,
        ModalItemsChooseComponent,
        ButtonInfoComponent,
        PillComponent,
        ModalDateChooserComponent,
        ModalCourseModuleFormComponent,
    ]
})
export class SharedModule { }
